.themes-v1 {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-auto-rows: minmax(120px, auto);
	grid-gap: 1.5em;
	grid-template-areas:
		'img1 img1 img2 img2 img3 img3'
		'img1 img1 img2 img2 img3 img3'
		'img4 img4 img5 img5 img6 img6'
		'img4 img4 img5 img5 img6 img6';

	@media (max-width: 990px) and (min-width: 768px) {
		grid-template-columns: repeat(2, 1fr);
		grid-template-areas:
			'img1 img2'
			'img3 img4'
			'img5 img6';

	}

	@media (max-width: 767px) {
		display: flex;
		flex-direction: column;
	}

	a {
		text-decoration: none
	}

	.img1 {
		grid-area: img1;
		background-image: url(RESOURCE/img/Kachel_Hunde_Willkommen.webp);
	}


	.img2 {
		grid-area: img2;
		background-image: url(RESOURCE/img/Kachel_Eigentuemerservice.webp);
	}

	.img3 {
		grid-area: img3;
		background-image: url(RESOURCE/img/Kachel_Fragen_und_Antworten.webp);

	}

	.img4 {
		grid-area: img4;
		background-image: url(RESOURCE/img/Kachel_Erdgeschoss.webp);
	}

	.img5 {
		grid-area: img5;
		background-image: url(RESOURCE/img/Kachel_Deichnahe.webp);
	}

	.img6 {
		grid-area: img6;
		background-image: url(RESOURCE/img/Kachel_Familienurlaub.webp);
	}



	[class^='theme-card'] {
		color: white;
		text-decoration: none;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: top;
		padding: 10px;
		transition: all .25s ease 0s;
		transform: scale(1);
		min-height: 300px;

		/*@media (max-width: 767px) {
			height: 35vh;
		}*/


		&:hover {
			text-decoration: none;
			transform: scale(1.015);
			box-shadow: 0 0 8px 0 rgba(0, 0, 0, .2);
			border-radius: var(--border-radius);
		}

		h2 {
			font-size: var(--theme-card-title);
			color: var(--theme-card-text-color);
		}

		p {
			font-size: var(--theme-card-description);
			color: var(--theme-card-text-color);
			margin-bottom: 5px;
		}

		a {
			text-decoration: none;
		}
	}

	.theme-card-1 {
		min-width: 100px;
		min-height: 100px;
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.55);
		background-blend-mode: multiply;
		transition: ease-out 0.5s;
	}

	.theme-card-2 {
		min-width: 100px;
		min-height: 100px;
		position: relative;
		width: 100%;
		height: 100%;
		color: white;
		text-decoration: none;
		font-size: 1.2em;
		transition: ease-out 0.5s;
		display: flex;
		align-items: center;
		justify-content: center;
	}


	.theme-card-3 {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding: 0;
		border-radius: var(--border-radius);
	}

	.theme-card-3--description-container {
		background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgb(0, 0, 0) 100%);
		padding: 30px 20px;
		transition: 1s;
		border-bottom-left-radius: var(--border-radius);
		border-bottom-right-radius: var(--border-radius);
		position: relative;
		display: flex;
		align-content: flex-end;
		flex-direction: column;
		justify-content: flex-end;

		.btn {
			background-color: var(--color-white);
			position: absolute;
			bottom: 30px;
			font-size: 14px;
			display: none;
		}
	}



	@media (min-width: 767px) {
		.theme-card-1--description-container .description {
			opacity: 0;
			transition: ease-out 0.5s;

		}

		.theme-card-1:hover .description {
			opacity: 1;
			transition: ease-in 0.5s;

		}

		.theme-card-1:hover {
			background-color: rgba(0, 0, 0, 0.55);
			background-blend-mode: multiply;
			transition: ease-in 0.5s;
		}

		.theme-card-1:not(:hover) {
			background-color: initial;
			background-blend-mode: multiply;
			transition: ease-out 0.5s;
		}

		.theme-card-2:hover {
			background-color: var(--overlay-color);
			background-blend-mode: multiply;
			transition: ease-in 0.5s;
		}

		.theme-card-2:not(:hover) {
			background-color: initial;
			background-blend-mode: multiply;
			transition: ease-out 0.5s;
		}

		.theme-card-3--description-container {
			height: 100%;
		}

		.theme-card-3--description-container .title {
			font-size: 22px;
			font-weight: 400;
		}

		/*.theme-card-3--description-container .description {
			padding: 30px;
		}*/

		.theme-card-3:hover .theme-card-3--description-container {
			height: 100%;
			transition: 1s;
		}

		/*.theme-card-3 .theme-card-3--description-container>p {
			opacity: 0;
			display: none;
		}

		.theme-card-3:hover .theme-card-3--description-container>p {
			opacity: 1;
			transition: 1s ease-in;
			display: block;
		}*/
	}

	@media (max-width: 766px) {

		.theme-card-3--description-container .title {
			font-size: 22px;
			font-weight: 400;
		}
	}
}