.index-page-layout-v1 {
	margin-top: var(--page-margin-top);

	h2 {
		text-align: center;
	}

	.subtitle {
		text-align: center;
		text-indent: 0px;
	}

	.index-map {
		.leaflet-popup-content {
			margin: 0;
			padding: 15px;
		}
	}

	.highlights {
		display: flex;
		justify-content: space-between;

		@media (max-width:992px) {
			flex-wrap: wrap;
		}


		.highlight {
			text-align: center;
			color: var(--color-black);
			padding: 10px;
			position: relative;

			.icon {
				margin-bottom: 10px;
				margin-top: 20px;
				font-size: 36px;
				color: var(--color-primary);
				height: 54px;

				.fa-icon {
					width: 35px;
					height: 35px;
					display: inline-block;
					background-position: center;
					background-repeat: no-repeat;
					background-size: contain;
					filter: brightness(0) saturate(100%) invert(25%) sepia(100%) saturate(338%) hue-rotate(131deg) brightness(90%) contrast(100%);

					&.icon-handshake {
						background-image: url(RESOURCE/img/icon-handshake.svg);
						width: 40px;
						height: 40px;
					}

					&.icon-heart {
						background-image: url(RESOURCE/img/icon-heart-solid.svg);
					}

					&.icon-house-circle-check {
						background-image: url(RESOURCE/img/icon-house-circle-check.svg);
						height: 40px;
					}

					&.icon-key {
						background-image: url(RESOURCE/img/icon-key.svg);
						width: 32px;
						height: 32px;
					}
				}
			}

			.title {
				font-size: 20px;
				margin-bottom: 10px;
				font-weight: 600;
			}

			.text {
				font-size: 18px;
				padding-bottom: 30px;
				margin: 0 auto;

				@media(max-width: 1023px) {
					max-width: 300px;
				}

			}
		}
	}

}