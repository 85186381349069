.norddeichentdecken {

	.inner-banner {
		background-image: url(RESOURCE/img/hero_region.webp);
		background-position: 50% 75%;
	}

	.lead {
		font-size: 18px;
	}

	.image {
		img {
			width: 100%;
			object-fit: cover;
		}
	}

	.pr {
		@media(min-width: 768px) {
			padding-right: 15px
		}

		@media(min-width: 992px) {
			padding-right: 30px
		}
	}

	.pl {
		@media(min-width: 768px) {
			padding-left: 15px
		}

		@media(min-width: 992px) {
			padding-left: 30px
		}
	}

	.content-col {

		@media(min-width: 992px) {
			font-size: 18px;
		}

		.title {
			font-weight: bold;
			padding-bottom: 15px;
			font-size: 20px;
		}

		.text {
			text-align: justify;
		}
	}


}