.eigentuemerservice {

	.inner-banner {
		background-image: url(RESOURCE/img/eigentuemerservice_hero.webp);
		background-position: 50% 70%;
	}

	.image {
		img {
			width: 100%;
			object-fit: cover;
		}

		img.fixratio {
			aspect-ratio: 3 / 2;
		}
	}

	.text-justify {
		text-align: justify;
	}

	.lead {
		font-size: 18px;
	}


	.service-box {
		/*background: var(--color-grey-light);*/
		padding: 15px 15px 0;

		.point {
			display: flex;
			align-items: flex-start;
			gap: 8px;
			line-height: 1.5;
			margin-bottom: 15px;

			i {
				flex-shrink: 0;
				margin-top: 3px;
				font-size: 20px;
				color: var(--color-green);
			}
		}
	}

	.max-w-600 {
		max-width: 600px;
		margin: 0 auto;
	}

	.accordion {
		width: 100%;
		margin: 20px auto;
		overflow: hidden;
		max-width: 600px;
	}

	.accordion-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px;
		cursor: pointer;
		font-size: 18px;
		font-weight: 600;
		border-bottom: 1px solid var(--color-grey-normal);
	}

	.accordion-header:hover,
	.accordion-header.active {
		//background-color: var(--color-grey-light);
		color: var(--color-primary);
	}

	.accordion-content {
		display: none;
		padding: 10px;
		/*background-color: #fff;*/

		.tick-li {

			li {
				width: 100%;
				padding-left: 30px;
				padding-right: 15px;
				min-height: 30px;
				position: relative;


				&:before {
					content: "\f00c";
					font-family: "Font Awesome 6 Sharp";
					-webkit-font-smoothing: antialiased;
					font-weight: 600;
					display: inline-block;
					position: absolute;
					left: 0;
					top: 0;
					color: var(--color-green);
				}
			}
		}
	}

	.accordion-header .icon {
		font-size: 20px;
		transition: transform 0.3s ease;
	}

	.accordion-header.active .icon {
		transform: rotate(360deg);
	}

}